
const WTData1 = () => [

	{
		id: 1,
		sn:"1",
		date: "2066÷05÷13",
		location: ";d'Gb|dg ljBf k|lti&fg, ^+ufn, sf&df*f}++",
		participants: "30",
		collection: "31"
	},
	{
		id: 2,
		sn:"2",
		date: "2067÷05÷19",
		location: ";d'Gb|dg ljBf k|lti&fg, ^+ufn, sf&df*f}++",
		participants: "30",
		collection: "30"
	},
	{
		id: 3,
		sn:"3",
		date: "2068÷05÷03",
		location: ";d'Gb|dg ljBf k|lti&fg, ^+ufn, sf&df*f}++",
		participants: "30",
		collection: "30"
	},
	{
		id: 4,
		sn:"4",
		date: "2069÷05÷02",
		location: "sdng]q %'gfd'gf pRr dflj jfg]Zj/ sf&df*f}+",
		participants: "39",
		collection: "39"
	},
	{
		id: 5,
		sn:"5",
		date: "2070÷05÷01",
		location: "नेपाल कलेज अफ ट्राभल एण्ड टुरिजम, गौशाला, काठमाडौं",
		participants: "34",
		collection: "34"
	},
	{
		id: 6,
		sn:"6",
		date: "2071÷05÷07",
		location: "Pk]S; sn]h, jfg]Zj/, sf&df*f}+",
		participants: "26",
		collection: "26"
	},
	{
		id: 7,
		sn:"7",
		date: "2072÷05÷05",
		location: "uf]N*]gu]^ O)^/g]zgn sn]h aQL;k'tnL sf&df*f}+",
		participants: "25",
		collection: "25"
	},
	{
		id: 8,
		sn:"8",
		date: "2073÷05÷04",
		location: "cu|jfn ;]jf s]Gb|, cu|jfn ejg, sdnkf]v/L sf&df*f}+",
		participants: "31",
		collection: "31"
	},
	{
		id: 9,
		sn:"9",
		date: "2074÷04÷14",
		location: "j]bljBf>d ;+:s[t dflj jgsfnL uf}zfnf sf&df*f}+",
		participants: "27",
		collection: "27"
	}
]

export default WTData1;
