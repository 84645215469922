import React from 'react';
import PayPal from './paypal';
import Bank from './Bank';
import Esewa from './esewa';
import Card from './card';



const Donate = () => {

	return (

		<>

			<div className="card-body" >
				<h5 className="card-title fw-bold fs-4 text-info">JUSTIFY YOUR PURPOSE</h5>
				<p className="card-text">MAKE YOUR DONATION TO CHANGE LIVES OF OTHERS</p>
				<span className="placeholder col-12 bg-danger"></span>
				<h5 className="card-title fw-bold fs-4 text-danger">
					NOTE: Please select mode of donation. We encourage you to ask receipt after payment is made.
				</h5>
			</div>

			<div className="p-3">
				<div className="card text-center">
					<div className="card-header">
						<ul className="nav nav-pills" id="pills-tab" role="tablist">
							<li className="nav-item" role="presentation">
								<button className="nav-link active" id="pills-paypal-tab" data-bs-toggle="pill" data-bs-target="#pills-paypal" type="button" role="tab" aria-controls="pills-paypal" aria-selected="true">PayPal</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-bank-tab" data-bs-toggle="pill" data-bs-target="#pills-bank" type="button" role="tab" aria-controls="pills-bank" aria-selected="false">Bank Transfer/Deposits</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-esewa-tab" data-bs-toggle="pill" data-bs-target="#pills-esewa" type="button" role="tab" aria-controls="pills-esewa" aria-selected="false">e-sewa/Khalti</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-card-tab" data-bs-toggle="pill" data-bs-target="#pills-card" type="button" role="tab" aria-controls="pills-card" aria-selected="false">QR/Fonepay</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-cash-tab" data-bs-toggle="pill" data-bs-target="#pills-cash" type="button" role="tab" aria-controls="pills-cash" aria-selected="false">CASH</button>
							</li>
						</ul>
					</div>

					<div className="tab-content card-body" id="nav-tabContent">
						<div className="tab-pane fade show active" id="pills-paypal" role="tabpanel" aria-labelledby="nav-paypal-tab">
							<PayPal />
						</div>
						<div className="tab-pane fade" id="pills-bank" role="tabpanel" aria-labelledby="nav-bank-tab">
							<Bank />
						</div>
						<div className="tab-pane fade" id="pills-esewa" role="tabpanel" aria-labelledby="nav-esewa-tab">
							<Esewa />
						</div>
						<div className="tab-pane fade" id="pills-card" role="tabpanel" aria-labelledby="nav-card-tab">
							<Card />
						</div>
						<div className="tab-pane fade" id="pills-cash" role="tabpanel" aria-labelledby="nav-cash-tab">
							<p className="py-5 fw-bold">
								Please call 9841612703, our member will contact you through same number for collection.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>

	);
}

export default Donate;