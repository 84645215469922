const BhojanData = () => [

    {
		id:1,
		source:"images/gallery/bs2078/1.jpg",
		description:""
	},
    {
		id:2,
		source:"images/gallery/bs2078/2.jpg",
		description:""
	},
    {
		id:3,
		source:"images/gallery/bs2078/3.jpg",
		description:""
	},
    {
		id:4,
		source:"images/gallery/bs2078/4.jpg",
		description:""
	},
    {
		id:5,
		source:"images/gallery/bs2078/5.jpg",
		description:""
	},
    {
		id:6,
		source:"images/gallery/bs2078/6.jpg",
		description:""
	},
    {
		id:7,
		source:"images/gallery/bs2078/7.jpg",
		description:""
	},
   



]
export default BhojanData