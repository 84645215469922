import React from 'react';


export default function chairmanCard({ APIdata }) {


	console.log(APIdata);

	return (
		<>
			{APIdata.map((curEle) => {

				const { id, image, name, fromtime, untiltime, description } = curEle;
				return (

					<>
						<div className="col py-2" key={id}>
							<div className="card bg-dark h-100 m-1 px-2 py-2">
								<img src={image} className="card-img-top" alt="..." />
								<div className="card-body">
									<h5 className="card-title fs-6 text-white">{name}</h5>
									<span className="text-danger">{fromtime} to {untiltime}</span>
									<p className="card-text">{description}</p>
								</div>
							</div>
						</div>
					</>
				);

			})
			}
		</>
	);

}