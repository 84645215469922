const OrdinaryData =() => [
    {
        id: 1,
        img: "images/ordinary/1.jpg",
        name: "Divine Mr Rambabu Kafle",
        address: ""
    },
    {
        id: 2,
        img: "images/ordinary/2.png",
        name: "Mr Tulsi Prasad Kafle",
        address: ""
    },
    {
        id: 3,
        img: "images/ordinary/3.png",
        name: "Mrs Neelima Kafle",
        address: ""
    },
    {
        id: 4,
        img: "images/ordinary/4.png",
        name: "Mrs Pawana Kafle",
        address: ""
    },
    {
        id: 5,
        img: "images/ordinary/5.png",
        name: "Dr Prakash Kumar Kafle",
        address: ""
    },
    {
        id: 6,
        img: "images/ordinary/6.png",
        name: "Mr Sudip Kafle",
        address: ""
    },
    {
        id: 7,
        img: "images/ordinary/7.png",
        name: "Mr Ravi Kumar Kafle",
        address: ""
    },
    {
        id: 8,
        img: "images/ordinary/8.png",
        name: "Mr Pradip Raj Kafle",
        address: ""
    },
]
export default OrdinaryData