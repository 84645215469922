import React from 'react';

const Paypal = () => {

	return (
		<>
			<div className="py-5">
				<h4>PAYPAL</h4>
				<p>We are working on it. Please choose another mode of payment.</p>

				<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
					<input type="hidden" name="cmd" value="_s-xclick" />
					<input type="hidden" name="hosted_button_id" value="HPC3L2XY4WNNJ" />
					<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" alt="PayPal - The safer, easier way to pay online!" />
					<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
				</form>
			</div>
		</>
	);

}

export default Paypal;