import React from 'react';
import elogo from '../images/e-logo.png';


const esewa = () => {

	return (
		<>
			<div className="card-text py-5">
			<p>Please send donation to brasumef@gmail.com or 9841612703 using your esewa account.</p>
				<form action="https://uat.esewa.com.np/epay/main" method="POST">
					<img className="" src={elogo} alt="" />
					<input value="100" name="tAmt" type="hidden" />
					<input value="90" name="amt" type="hidden" />
					<input value="5" name="txAmt" type="hidden" />
					<input value="2" name="psc" type="hidden" />
					<input value="3" name="pdc" type="hidden" />
					<input value="EPAYTEST" name="scd" type="hidden" />
					<input value="ee2c3ca1-696b-4cc5-a6be-2c40d929d453" name="pid" type="hidden" />
					<input value="http://merchant.com.np/page/esewa_payment_success?q=su" type="hidden" name="su" />
					<input value="http://merchant.com.np/page/esewa_payment_failed?q=fu" type="hidden" name="fu" />
					<input value="Select" type="submit" />
				</form>

			</div>
		</>
	);

}

export default esewa;
