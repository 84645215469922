const Founder = () => [
    {
        id: 1,
        img: "images/founder/1.jpg",
        name: "Mr Badri Prasad Kafle",
        address: ""
    },
    {
        id: 2,
        img: "images/founder/2.jpg",
        name: "Mr Kabindra Raj Sharma Kafle",
        address: ""
    },
    {
        id: 3,
        img: "images/founder/3.jpg",
        name: "Mr Janak Raj Upadhyay",
        address: ""
    },
    {
        id: 4,
        img: "images/founder/4.jpg",
        name: "Dr Surendra Raj Kafle",
        address: ""
    },
    {
        id: 5,
        img: "images/founder/5.jpg",
        name: "Divine Mr Ramesh Kumar Kafle",
        address: ""
    },
    {
        id: 6,
        img: "images/founder/6.jpg",
        name: "Mr Binodbabu Kafle",
        address: ""
    },
    {
        id: 7,
        img: "images/founder/7.jpg",
        name: "Mr Ramesh Kumar Kafle",
        address: ""
    },
    {
        id: 8,
        img: "images/founder/8.jpg",
        name: "Mr Saroj Raj Sharma Kafle",
        address: ""
    },
    {
        id: 9,
        img: "images/founder/9.jpg",
        name: 'Mr Gun Raj Kafle "Sandilya"',
        address: ""
    },
    {
        id: 10,
        img: "images/founder/10.jpg",
        name: "Dr Dinesh Raj Sharma Kafle",
        address: ""
    },
    {
        id: 11,
        img: "images/founder/11.jpg",
        name: "Divine Mr Rishi Raj Kafle",
        address: ""
    },
    {
        id: 12,
        img: "images/founder/12.jpg",
        name: "Mr Upendra Raj Kafle",
        address: ""
    },
    {
        id: 13,
        img: "images/founder/13.png",
        name: "Mr Suresh Raj Kafle",
        address: ""
    },
    {
        id: 14,
        img: "images/founder/14.jpg",
        name: "Mr Dipak Raj Kafle",
        address: ""
    },
    {
        id: 15,
        img: "images/founder/15.png",
        name: "Mr Kumar Raj Kafle",
        address: ""
    },
    {
        id: 16,
        img: "images/founder/16.jpg",
        name: "Dr Rishi Kumar Kafle",
        address: ""
    },
    {
        id: 17,
        img: "images/founder/17.jpg",
        name: "Mr KoshRaj Kafle",
        address: ""
    },
    {
        id: 18,
        img: "images/founder/18.jpg",
        name: "Mr Bhagawati Kumar Kafle",
        address: ""
    },
    {
        id: 19,
        img: "images/founder/19.jpg",
        name: "Mr Naw Raj Kafle",
        address: ""
    },
    {
        id: 20,
        img: "images/founder/20.jpg",
        name: "Mr Govind Pd Sharma Kafle",
        address: ""
    },
    {
        id: 21,
        img: "images/founder/21.png",
        name: "Mr Chewan Prasad Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 22,
        img: "images/founder/22.jpg",
        name: "Mr Medan Prasad Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 23,
        img: "images/founder/23.jpg",
        name: "Mrs Kalpana Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 24,
        img: "images/founder/24.png",
        name: "Mr Achyut Raj Kafle",
        address: ""
    },
    {
        id: 25,
        img: "images/founder/25.jpg",
        name: "Mr Durga Kumar Kafle",
        address: ""
    },
    {
        id: 26,
        img: "images/founder/26.jpg",
        name: "Mr Rajendra Kumar Kafle",
        address: ""
    },
    {
        id: 27,
        img: "images/founder/27.jpg",
        name: "Mr Manoj Kumar Kafle",
        address: "Kaushaltar, Bhaktapur"
    },
    {
        id: 28,
        img: "images/founder/28.jpg",
        name: "Mrs Pabita Kafle",
        address: "Kaushaltar, Bhaktapur"
    },
    {
        id: 29,
        img: "images/founder/29.jpg",
        name: "Mr Pasupati Kumar Kafle",
        address: ""
    },
    {
        id: 30,
        img: "images/founder/30.png",
        name: "Mr Sushil Kumar Kafle",
        address: ""
    },
    {
        id: 31,
        img: "images/founder/31.png",
        name: "Mr Mahesh Kumar Kafle",
        address: ""
    },
    {
        id: 32,
        img: "images/founder/32.jpg",
        name: "CA Sujan Kumar Kafle",
        address: "Old Baneshwor, Kathmandu"
    },
    {
        id: 33,
        img: "images/founder/33.png",
        name: "Mr Niranjan Kumar Kafle",
        address: ""
    },
    {
        id: 34,
        img: "images/founder/34.png",
        name: "Mr Binod Kumar Kafle",
        address: ""
    },
    {
        id: 35,
        img: "images/founder/35.jpg",
        name: "Dr Kumud Raj Kafle",
        address: ""
    },
    {
        id: 36,
        img: "images/founder/36.jpg",
        name: "Mr Dipesh Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 37,
        img: "images/founder/37.jpg",
        name: "Mr Dipak Kumar Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 38,
        img: "images/founder/38.png",
        name: "Mr Sijan Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 39,
        img: "images/founder/39.png",
        name: "Mr Gandiv Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 40,
        img: "images/founder/40.jpg",
        name: "Mr Abishek Kafle",
        address: "NC, USA"
    }


]

export default Founder