
export default function chairmanAPI() {

	return [{
		id: 1,
		image: "./images/chairman/kabindra.jpg",
		name: "KABINDRA RAJ SHARMA KAFLE",
		fromtime: "2065-04-18",
		untiltime: "2066-04-09",
		description: ""
	},

	{
		id: 2,
		image: "./images/chairman/saroj.jpg",
		name: "SAROJ RAJ SHARMA KAFLE",
		fromtime: "2066-04-10",
		untiltime: "2068-05-02",
		description: ""
	},

	{
		id: 3,
		image: "./images/chairman/binodbabu.jpg",
		name: "BINOD BABU KAFLE",
		fromtime: "2068-05-03",
		untiltime: "2070-04-32",
		description: ""

	},

	{
		id: 4,
		image: "./images/chairman/gunaraj.jpg",
		name: "GUNARAJ KAFLE 'SHANDILYA'",
		fromtime: "2070-05-01",
		untiltime: "2074-04-13",
		description: ""
	},

	{
		id: 5,
		image: "./images/committee/govinda.jpg",
		name: "GOVINDA PRASAD SHARMA KAFLE",
		fromtime: "2074-04-14",
		untiltime: "--",
		description: ""
	},


	]

}