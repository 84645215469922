import React, { useState } from 'react';

import DataC from '../components/data/nepaliData'
import WTable1 from './WhereTable1';
import Wdata from './WTData1';
import camp1 from '../images/camp1.jpg'
import camp2 from '../images/camp2.jpg'
import WTd2 from './WTdata2';
import WhereT2 from './WhereTable2';

const Where = () => {

	const [wtdata, setwtdata] = useState(Wdata);
	const [WT2, setWT2] = useState(WTd2);
	const [DColl, setDColl] = useState (DataC);

	return (
	
		<div className=''>
			<div className="card-body mb-3 p-3 w-100">
				<h5 className="card-title fw-bold fs-4 text-info">OUR WORK</h5>
				<p className="card-text">Our effort for better community</p>
			</div>

			<div className="row text-center p-5">
				<h3 className="fs-5 text-danger fw-bold">EDUCATION</h3>
				<span className='text-muted'>FINANCIAL ASSISTANCE FOR STUDY</span>
				<div className="card-text text-start py-2">
				 <span className='card-text fs-lg nepali '>	{DColl.education} </span>
				</div>
			</div>

			<div className="card bg-light text-center p-5">
				<h3 className="fs-5 text-danger fw-bold">Infrastructure</h3>
				<span className='text-muted'>HELP BUILD FOR COMMUNITY</span>
				<div className="card-text text-start py-2">
				 <span className='card-text fs-lg nepali '>	{DColl.infra} </span>
				</div>
			</div>

			
			<div className="row text-center p-5">
				<h3 className="fs-5 text-danger fw-bold">HELPING HANDS</h3>
				<span className='text-muted'>HELPING COMMUNITY IN-NEED</span>
				<div className="card-text text-start py-2">
				 <span className='card-text fs-lg nepali '>	{DColl.dist} </span>
				</div>
			</div>

			<div className="card bg-light">
				<div className="card-header text-danger fw-bold bg-grey mb-3 text-center py-3">
					HEALTH
					<div className="text-muted">Blood Donation</div>
				</div>
				<div className="card-body">

					<div className="row">
						<div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
							<img
								src="./images/blood/1.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Boat on Calm Water"
							/>

							<img
								src="./images/blood/5.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Wintry Mountain Landscape"
							/>
						</div>

						<div className="col-lg-4 mb-4 mb-lg-0">
							<img
								src="./images/blood/6.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Mountains in the Clouds"
							/>

							<img
								src="./images/blood/2.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Boat on Calm Water"
							/>
						</div>

						<div className="col-lg-4 mb-4 mb-lg-0">
							<img
								src="./images/blood/3.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Waves at Sea"
							/>

							<img
								src="./images/blood/7.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Yosemite National Park"
							/>
						</div>
						<div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
							<img
								src="./images/blood/8.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Boat on Calm Water"
							/>

							<img
								src="./images/blood/14.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Wintry Mountain Landscape"
							/>
						</div>
						<div className="col-lg-4 mb-4 mb-lg-0">
							<img
								src="./images/blood/17.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Mountains in the Clouds"
							/>

							<img
								src="./images/blood/18.jpg"
								className="w-100 shadow-1-strong rounded mb-4"
								alt="Boat on Calm Water"
							/>
						</div>
					</div>

				</div>

			</div>

			<div className="row text-center py-5">
				<h3 className="card-title">Blood Donation Programme</h3>
				<span>Our attempt for Better Community</span>
				<div className="row row-cols-1 row-cols-md-3 p-5">
					<WTable1 WTabledata={wtdata} />
				</div>
			</div>
			<div className="card bg-light">
				<div className="card-header text-danger fw-bold bg-grey mb-3 text-center py-3">
					HEALTH
					<div className="text-muted">Free Check-up Camp</div>
				</div>
				<div class="row row-cols-1 row-cols-md-3 g-4">
					<div class="col p-5"><img
						src={camp1}
						className="w-100 shadow-1-strong rounded mb-4"
						alt="Boat on Calm Water"
					/></div>
					<div class="col p-5"><img
						src={camp2}
						className="w-100 shadow-1-strong rounded mb-4"
						alt="Boat on Calm Water"
					/></div>
				</div>
			</div>

			<div className="row text-center py-5">
				<h3 className="card-title">Free Health Check-up Camp Programme</h3>
				<span>Our attempt for Better Community</span>
				<div className="row row-cols-1 row-cols-md-3 p-5">
					<WhereT2 WTD2={WT2} />
				</div>
			</div>
		</div>
	);
}
export default Where;