import React, { useState } from 'react';
import Founder from './founder';
import FData from '../components/data/founderData'
import Lifetime from './Lifetime';
import LTData from '../components/data/lifetimeData'
import OrdinaryData from './data/ordinaryData'
import Ordi from './ordinary';
import HonaryData from './data/HonaryData';
import Hono from './honorary';
import guardianD from './data/guardianData'
import Guard from './guardian'


const Knowledge = () => {

	const [FM, setFM] = useState(FData)
	const [LTM, setLTM] = useState(LTData)
	const [OD, setOD] = useState(OrdinaryData)
	const [HD, setHD] = useState(HonaryData)
	const [GD, setGD] = useState(guardianD)

	return (
		<>
			<div className="card-body mb-3 p-2 w-100">
				<h5 className="card-title fw-bold fs-4 text-info">KNOWLEDGE TO SHARE</h5>
				<p className="card-text">for community</p>
			</div>
			<div className='py-4'>
				<div className="card bg-light mb-4">
					<div className="text-danger fs-5 fw-bold bg-grey mb-3 text-center py-3">OUR FOUNDING MEMBERS
						<div className='text-muted fs-6'>BUILD FOR COMMUNITY</div>
					</div>
					<div className="row row-cols-1 row-cols-md-3">
						<Founder Founders={FM} />
					</div>
				</div>
			</div>

			<div className='card mb-4'>
				<div className="text-center py-4">
					<h3 className="text-danger fs-4 fw-bold">GUARDIANS </h3>
					<span className='text-muted'>CARE FOR COMMUNITY </span>
				</div>
				<div className="row row-cols-1 row-cols-md-3">
					<Guard gData={GD} />
				</div>
			</div>

			<div className='py-4'>
				<div className="card bg-light mb-4">
					<div className="text-danger fw-bold bg-grey mb-3 text-center  fs-5 py-4">LIFE-TIME MEMBERS 
						<div className='text-muted fs-6'>ASSISTANCE TO BUILD COMMUNITY </div>
					</div>
					<div className="row row-cols-1 row-cols-md-3 py-2">
						<Lifetime LTMem={LTM} />
					</div>
				</div>
			</div>

			<div className='card mb-4'>
				<div className="text-center py-4">
					<h3 className="text-danger fs-5 fw-bold">ORDINARY MEMBERS</h3>
					<span className='text-muted'>FOR COMMUNITY </span>
				</div>
				<div className="row row-cols-1 row-cols-md-3 ">
					<Ordi OData={OD} />
				</div>
			</div>

			<div className='py-4'>
				<div className="card bg-light mb-4">
					<div className="text-danger fs-5 fw-bold bg-grey mb-3 text-center py-4">OUR HONORARY MEMBERS
						<div className='fs-6 text-muted'>GRATITUDE FOR BUILDING COMMUNITY </div>
					</div>
					<div className="row row-cols-1 row-cols-md-3 py-2 justify-content-center">
						<Hono HData={HD} />
					</div>
				</div>
			</div>

		</>
	);
}

export default Knowledge;