import React from 'react';
import QR from '../images/qr.jpg'

const card = () => {

	return (
		<>
			<p className="py-5 fw-bold">
				Please scan the QR code from the image below.
			</p>
			<div className='card responsive'>
				<img className="img-fluid" src={QR}/>
			</div>
		</>
	);
}

export default card;