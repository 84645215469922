
export default function () {
	
	return[
		{
			id: 1,
			image: "images/committee/govinda.jpg",
			name: "GOVINDA PRASAD SHARMA KAFLE",
			position: "Chairman",		
		},
		
		{
			id: 2,
			image: "images/committee/sushil.jpg",
			name: "Sushil Kumar Kafle",
			position: "Vice-Chairman",		
		},
		
		{
			id: 3,
			image: "images/committee/sujan.jpg",
			name: "CA Sujan Kumar Kafle",
			position: "Secretary",		
		},
		
		{
			id: 4,
			image: "images/committee/prabin.jpg",
			name: "CA Prabin Kafle",
			position: "Treasurer",		
		},
		
		{
			id: 5,
			image: "images/chairman/gunaraj.jpg",
			name: "Mr Gun Raj Kafle 'Sandilya'",
			position: "Ex- officio Member ",		
		},
		
		{
			id: 6,
			image: "images/committee/ramesh.jpg",
			name: "Mr Ramesh Kumar Kafle",
			position: "Member",		
		},
		
		{
			id: 7,
			image: "images/committee/pashupati.jpg",
			name: "Mr Pasupati Kumar Kafle",
			position: "Member",		
		},
		
		{
			id: 8,
			image: "images/committee/mahesh.png",
			name: "Mr Mahesh Kumar Kafle",
			position: "Member",		
		},
		
		{
			id: 9,
			image: "images/committee/noimg.jpg",
			name: "Mrs Swosti Kafle Subedi",
			position: "Member",		
		},
		
		{
			id: 10,
			image: "images/committee/kumud.jpg",
			name: "Dr Kumud Raj Kafle",
			position: "Member",		
		},
		
		{
			id: 11,
			image: "images/committee/noimg.jpg",
			name: "Mrs Puja Pokharel Kafle(Sony)",
			position: "Member",		
		},
		
	]
	
}