import React from 'react';
import logo from '../BSMF_logo.png';
import flag from '../images/np.gif'
import '../css/header.css';
import Dont from './Donate';
import C2079 from '../docs/2079Calender.pdf';

export default function header() {


	return (


		<div className="container-fluid">
			<a className="LogoBtn bg-transparent" href="/">
				<img src={logo} alt="BRASUMEF" width="150" height="150" className="" /> <img src={flag} alt="BRASUMEF" width="220" height="140" className="" />
			</a>

			<button type="button" className="btn btn-outline-warning specialBtn specialBtn-right" onClick={() => { alert("Please follow to Donate Tab on side navigation bar. Thank you."); return (<><Dont /></>) }}>
				<div className="position-absolute top-20 start-20"><i className="fa-solid fa-heart fa-3x"></i></div>
				<div className="dtext"> DONATE NOW </div>
				<div className="small"> helping community</div>
			</button>
			<a href={C2079} target="_blank" download="2079 Wall Calender" className='link-info'>
				<button type="button" className="btn btn-outline-warning specialBtnCalender specialBtn-right">
					<div className="float-start">

						<i class="fa-solid fa-download fa-xl fa-bounce"></i>

					</div>
					<span className="dtext"> 2079 Wall Calender </span>
				</button>
			</a>
		</div>



	);

}

