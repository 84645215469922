import React from 'react';
import Carousel from './carousel';
import Inner from './InnerBody';

export default function appContainer() {

	return (

		<>
			<Carousel />
			<Inner />
		</>


	);
}

