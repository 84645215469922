import React, { useEffect } from 'react'
import TemImg from '../images/temple.jpg'
import BOQ from '../docs/boq.pdf'

const Infra = () => {
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div class="row my-md-3 ps-md-3">
            <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                <div className="my-3 p-3">
                    <h2 className="display-5">Our Durga Temple</h2>
                    <p className="lead">Of the people, by the people, for the people.</p>
                </div>
                <img src={TemImg} className="img-fluid" alt="Temple"></img>
                <p className="card-text textJust p-5"><small className="text-muted">
                    The Braja Mohan Subhadra Devi Memorial Foundation has planned to build Durga Panchayan (Durga, Ganesh, Surya, Vishnu and Shiva) temples at Salu Dhungechap in front of Gaurishankar, Mount Everest snow range under the leadership of the foundation. It is believed that this will attract foreign tourists, promote the tradition, religion and culture that has been practiced from time immemorial, preserve moral and spiritual development and awakening, increase internal and religious tourism and increase the economic status of local people.
                    The construction and cost of the Durga Temple has been estimated by Naxal Kathmandu resident veteran senior engineer Mr. Shankar Nath Rimal. The construction of the temple will cost around Rs. 60 million.

                </small>
                </p>
                <div className="bg-dark shadow-sm mx-auto text-white style p-4">
                    <p >
                        Please download BOQ for some of the main materials that will be used during construction of the temple.
                    </p>

                    <div className="row text-center py-5">
                        <h3 className="card-title">Bill Of Quantity</h3>
                        <span>Our attempt for Better Community</span>
                        <div className="row row-cols-1 p-5">
                        <a href={BOQ} target="_blank" download="BOQ_Temple" className='link-info'>BOQ of Temple</a>
                        </div>
                    </div>

                </div>
                <div className='p-4'>
                    <p className="card-text textJust p-4"><small className="text-muted">
                        We are hopeful that the construction of the temple will be completed with the cooperation of all in the belief that the sea will be built with a little bit of collection. A policy has been adopted to accept materials and labor used in the construction of temples. If there is contribution of more than one person in any material, it will be used proportionally in other materials of the temple. We are confident that a small portion of the income will be devoted to the life of the layman by taking the sacred work of building the temple as an opportunity.
                        Land has been arranged for the construction of the temple. The initial phase of construction of the temple has been completed. Some financial support has been received for this work and some is in the process of being received. He also expressed his commitment to provide support from many people.
                        The contribution of the philanthropists dedicated by mind, word and deed will be accounted for and the account of labor, goods and cash will be made public at certain intervals by adopting the spirit of transparency. The name of the donor who contributes more than a certain amount will be mentioned in the inscription or copperplate.
                        We look forward to your invaluable support and advice in the construction and operation of Durga Temple. The establishment will highly appreciate their invaluable support.

                    </small>
                    </p>
                </div>
            </div>
        </div>
    )
}


export default Infra