
const NepaliData = () => (
	
	{
		wdata : `;dfhdf cfbz{ gful/s eO{ af¤Rgsf nflu clt cfjZos kg]{ lzIff, :jf:Yo, ;+:sl[t Pj+ cfly{s s[ofsnfksf dfWodaf^ lktf k'vf{ / :jod\ cfkm\gf] hGd ePsf] dft[e"lddf ;+ul&t Pj+ ;+:yfut ?kdf ;+nUg eO{ cfTdf b]lv g} ;dfh ;]jfdf nfUg clek|]l/t ePsf]n] ljsf;, dfgj clwsf/, dfgjtfjfb, jftfj/)f ;+/If)f, dlxnflxt / ;zQmLs/)f, lk%l*Psf ju{ / ;d'bfosf] pTyfg / cNk;+Vossf] ;+/If)fsf lglDt cfaZos sfo{x? ug]{ d'gfkmf /lxt hgsNof)fsf/L, hglxtsf/L ljz'$ ;fdflhs ;+:yfsf] ?kdf j|hdf]xg ;'eb|f b]jL :d[lt k|lti&fgsf] :yfkgf ePsf] % . of] k|lti&fg 2065 ;fn kf}if 17 ut] btf{ g+=541 df lhNnf k|zf;g sfof{no, /fd]%fkdf lalwjt?kdf btf{ ePsf]% . cfos/ %'^ kfpg] ;+:yfsf] ?kdf cfGtl/s /fh:j sfof{no, eQmk'/df btf{ ePsf] of] k|lti&fgnfO{ ;dfh sNof)f kl/ifbn] cfjWbtf k|bfg u/]sf]% .`,
		wtData : "Hello",
		education : `k|lti&fgsf] :yfkgf sfn b]lvg} >L ;]tLb]jL pRr dfWolds ljBfno, ;fn' /fd]%fkdf k|To]s z}lIfs ;qdf cWoog/t 10 hgf ljBfyL{x?nfO{ %fqj[lQ k|bfg ub}{ cfO{/x]sf]% .`,
		infra : `;fn' ('±é]%fksf] b]p/fnL *f*fsf] d"n ;*s b]lv vf]k|faf/L;Dd sl/a 1200 ld^/ ;*s lgdf{)f .`,
		dist : `2072 j}zfv 12 ut] zlgjf/ uPsf] dxfe"sDk / To; kl%sf] k/fsDkgn] cfxt ;j{;fwf/)fnfO{ s]xL /fxt k'uf]; eGg] p@]Zon] 2072 h]& 1 / 2 ut] /fd]%fk ;fn' r]ps]&f^L / ;fn' ('±]%fkaf^ k|lti&fgn] 390 yfg qLkfn ljt/)f u/]sf]% .

		`
	}
)


export default NepaliData;