import React from 'react';
import { NavLink } from 'react-router-dom';

const WhatCard = ({ Wdata }) => {

	console.log(Wdata);
	return (
		<>{
			Wdata.map((curE) => {
				const { id, title, image, url, description } = curE;
				return (
					<NavLink to={url}>
						<div className="col" key={id.toString()} value={id}>
							<div className="card">
								<img src={image} className="card-img-top" alt="" />
								<div className="card-body">
									<h5 className="card-title">{title}</h5>
									<p className="card-text">
										{description}
									</p>
								</div>
							</div>
						</div>
					</NavLink>
				);
			}
			)
		}
		</>
	);
}

export default WhatCard;