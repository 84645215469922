import React from 'react'

const ordinary = ({ OData }) => {
    return (
        <>
            {
                OData.map((cureE) => {

                    const { id, img, name, address } = cureE
                    return (
                        <div div className="col" key={id.toString()} value={id} >
                            <div className="card m-1 border-success">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <img className="img-thumbnail rounded-start" src={img} alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <small>{id}</small>
                                            <h5 className="smallText fw-bold">{name}</h5>
                                            <p className="card-text"><small className="text-muted fw-bold">{address}</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                })
            }
        </>
    )
}

export default ordinary