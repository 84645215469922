import React, { useState } from 'react';
import ChairmanCard from './chairmanCard.js';
import WhoAPI from './chairmanAPI.js';
import CAPI from './committeeAPI.js';
import CCard from './committeeCard.js';
import ND from './data/nepaliData';
import Main from '../images/main.jpg'

const Who = () => {

	const [WhoAPIdata, setWhoAPIdata] = useState(WhoAPI);
	const [cAPIdata, setcAPIdata] = useState(CAPI);
	const [VData, setVData] = useState(ND);

	return (

		<>
			<div className="container">
				<div className="mb-3 w-100 border-bottom border-3 py-4">
					<h5 className="card-title fw-bold fs-4 text-info">WE ARE A FAMILY TREE</h5>
					<p className="card-text">Our effort for better community</p>
				</div>
				<div className="row">
					<div className='row'>
						<div className="col-md-6">
							<div className="">
								<h5 className="card-title fw-bold fs-5 text-success">ABOUT BRASUMEF</h5>
								<p className="card-text">
									BRASUMEF is registered with Nepal Government at District Administration Office, RAMECHHAP with registered no. 541 on date 2065/09/17 BS and PAN 303700414.
								</p>
								<p className="card-text"><small className="text-muted">
									We are a non-profit organization which provides support & assistance to striving people allowing them to see hope & wish for the future. Together, we create simple solution that could benefit and touch the lives of people everyday. We act and help community and people to build, operate schools, improve health and welfare, protect rights, empower people, assist needed people.

									A hundred percent of donation received to our organization goes to different projects on different sector. To view details on receivable and expenses, please follow our annual report.
								</small>
								</p>
							</div>
						</div>

						<div className="col-md-6">
							<img src={Main} className="img-fluid rounded" alt="..." />
						</div>
					</div>
					<div className='row p-4'>
						<div className="border border-3 border-warning text-dark mb-3 bg-success bg-opacity-25 p-4">
							<div className="card-body">
								<h5 className="card-title">Why us?</h5>
								<p className="card-text fs-lg nepali">
									{VData.wdata}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className='row py-4 border-bottom border-3'>

					<div class="card-body text-justify">
						<h5 class="card-title">Our History</h5>
						<p class="card-text textJust">
							The Braja Mohan Subhadra Devi Memorial Foundation has been established as a non-profit, welfare-oriented, public welfare-oriented social organization working for humanitarianism, environment protection, women's welfare and empowerment, upliftment of backward classes and communities and protection of minorities, through education, health, cultural and economic activities, which are essential for the survival of the ideal citizen in the society, the fathers and themselves are motivated to engage in social service from the soul by being organized and institutionally involved in their homeland.

							This establishment has been duly registered in the District Administration Office, Ramechhap in the registration no.541. The Social Welfare Council has given affiliation to this establishment which is registered in the Inland Revenue Office as an income tax exempt organization.
							The foundation has 40 founding members, 9 ordinary members, 2 honorary members and 25 life members. The 11-member working committee is carrying out the programs assigned by the foundation.
						</p>
					</div>
					<div class="card-body text-justify">
						<h5 class="card-title">Our Progress</h5>
						<p class="card-text textJust">
							The organization has been conducting regular blood donation program every year since its inception except in special circumstances. Out of the 10 blood donation programs conducted so far, 384 philanthropic blood donors have donated 385 pints of human blood in the program.

							Six free health camps have been conducted under the leadership of the organization. In these health camps, hundreds of dignitaries living far away from specialist specialists in kidney, nose, ear canal, gynecology, ophthalmology and orthopedic diseases have got the opportunity of free health care including medicine.

							Since its inception, Shree Setidevi Secondary School, Salu Ramechhap has been providing scholarships to 10 students studying in each academic session.

						</p>

					</div>

					<div class="card-body text-justify">
						<h5 class="card-title">Our Initiative</h5>
						<p class="card-text textJust">
							At the initiative of the foundation, a 1200 meter long road has been constructed from the main road of Salu Dhungechap Deurali Dada to Khoprabari.

							The Foundation has distributed 390 Tripals from Ramechhap, Salu Cheukethati and Salu Dhungechhap on Jeth 1 and 2, 2072 BS with the objective of providing some relief to the people injured by the Great Earthquake and its aftershocks.

							On the occasion of Maha Shivaratri of 2076 BS, a one day free food service was organized in Pashupati area of Kathmandu targeting the devotees. More than 35 dignitaries contributed cash, goods and labor for this food service and the food service provided in this program was received by about one thousand five hundred devotees.
							Similarly, on the occasion of Maha Shivaratri 2077 BS, a one day free meal service was organized in Pashupati area of Devpattan, Kathmandu on 27th Fagun, 2077 BS targeting the saints and visiting devotees. Among the programs conducted by the organization, the food service program was found to be liked by most of the esteemed members and dignitaries of the organization.

							On the occasion of Maha Shivaratri, 2078 BS, a two-day free vegetarian meal service was organized in Pashupat area, Devpattan, Kathmandu for the saints, pilgrims and devotees on Fagun 16 and 17, 2078 BS. The Foundation has the opportunity to serve thousands of the saints, pilgrims and devotees in this free food service.
							Covid-19 Coronavirus Infection Time On Saturday, Jeth 17, 2077 BS, Senior Nephrologist Dr. Rishi Kumar Kafle, Senior Otolaryngologist Mr. Prakash Kumar Kafle and Senior Cardiologist Dr. Om Murti Anil. The participants benefited from the concise views expressed by Dr. Om Murti Anil and the interaction between the health experts and the participants.

							The Foundation had organized a blessing exchange program on the occasion of Vijaya Dashami on the occasion of Bada Dashain 2077 through Zoom application on the occasion of Bada Dashain 2077 BS as it was difficult to receive blessings including Tika Jamara at the residence of Manyajan due to Covid-19 Corona virus infection. In this program, blessings were given by the devotees with heart, word and soul and the participants exchanged good wishes with each other.

						</p>

					</div>

					<h5 className='p-3'>The genealogy of the children and family member of Reverend Braja Mohan Upadhyaya Kafle with their shadows and the link of BRASUMEF FAMILY TREE has been placed in the Facebook group of the organization.</h5>


				</div>

				<div className="row text-center py-5">

					<h3 className="card-title">Chairman</h3>
					<span>Our Visonary Leaders For Better Community</span>
					<div className="row row-cols-1 row-cols-md-3">
						<ChairmanCard APIdata={WhoAPIdata} />
					</div>
				</div>

				<div className="row text-center py-5">

					<h3 className="card-title">Working Committee</h3>
					<span>For Better Community</span>
					<div className="row row-cols-1 row-cols-md-3">
						<CCard APIdata={cAPIdata} />
					</div>
				</div>

			</div>
		</>
	);

}

export default Who;