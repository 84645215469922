const guardianData = () => [

    {
        id: 0,
        img: "images/guardian/netra.jpg",
        name: "Mr Netra Raj Upadhayay Kafle",
        address: ""
    },
    {
        id: 1,
        img: "images/guardian/1.jpg",
        name: "Mr Badri Prasad Kafle",
        address: ""
    },
    {
        id: 2,
        img: "images/guardian/2.jpg",
        name: "Mr Kabindra Raj Sharma Kafle",
        address: ""
    },
    {
        id: 3,
        img: "images/guardian/3.jpg",
        name: "Mr Janak Raj Upadhyaya Kafle",
        address: ""
    },
    {
        id: 4,
        img: "images/guardian/4.jpg",
        name: "Dr Surendra Raj Sharma Kafle",
        address: ""
    },
    {
        id: 5,
        img: "images/guardian/5.jpg",
        name: "Mrs Sumitra Kafle",
        address: ""
    },
    {
        id: 6,
        img: "images/guardian/6.jpg",
        name: "Divine Mr Rambabu Kafle",
        address: ""
    },
    {
        id: 7,
        img: "images/guardian/7.jpg",
        name: "Divine Mr Ramesh Kumar Kafle",
        address: ""
    },
    {
        id: 8,
        img: "images/guardian/8.jpg",
        name: "Mr Binodbabu Kafle",
        address: ""
    },
    {
        id: 9,
        img: "images/guardian/9.jpg",
        name: "Mr Ramesh Kumar Kafle",
        address: ""
    },
    

]

export default guardianData
