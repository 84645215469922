import React from 'react';


const GetInvolved = () => {

	return (
		<>
			<div className="card-body p-2 w-100">
				<h5 className="card-title fw-bold fs-4 text-info">GET INVOLVED IN OUR ACTIVITIES</h5>
				<p className="card-text">we will update accordingly.</p>
			</div>
		</>
	);
}

export default GetInvolved;