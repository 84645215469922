

const EarthquakeData = () => [
	
	{
		id:1,
		source:"images/gallery/earthquake/1.JPG",
		description:""
	},
	
	{
		id:2,
		source:"images/gallery/earthquake/2.JPG",
		description:""
	},
	
	{
		id:3,
		source:"images/gallery/earthquake/3.JPG",
		description:""
	},
	
	{
		id:4,
		source:"images/gallery/earthquake/4.JPG",
		description:""
	},
	
	{
		id:5,
		source:"images/gallery/earthquake/5.JPG",
		description:""
	},
	
	{
		id:6,
		source:"images/gallery/earthquake/6.JPG",
		description:""
	},
	
	{
		id:7,
		source:"images/gallery/earthquake/7.JPG",
		description:""
	},
	
	{
		id:8,
		source:"images/gallery/earthquake/8.JPG",
		description:""
	},
	{
		id:9,
		source:"images/gallery/earthquake/9.JPG",
		description:""
	}	
	
]

export default EarthquakeData;