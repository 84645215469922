import React from 'react';


export default function committeeCard({ APIdata }) {

	console.log(APIdata);
	return (
		<>
			{
				APIdata.map((curE) => {
					
					const {id, image, name, position} = curE;

					return (
						<>
							<div className="col" key={id.toString()} value={id}>
								<div className="card m-1 border-dark border-2 mb-4">
									<div className="row g-0">
										<div className="col-md-4">
											<img className="img-thumbnail rounded-start" src={image} alt="..." />
										</div>
										<div className="col-md-8">
											<div className="card-body">
												<h5 className="smallText fw-bold">{name}</h5>
												<p className="card-text"><small className="text-muted fw-bold">{position}</small></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					);

				}
				)
			}


		</>
	);
}
