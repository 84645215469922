import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom'
import Header from './components/header';
import AppContainer from './components/appContainer';
import Infra from './components/infra';
import Footer from './components/footer';
import './App.css';

export default function App() {
	return (
		<div>
			<Header />
			<Routes>
				<Route exact path="/" element={<AppContainer />}/>
				<Route exact path="infra" element={<Infra />} />
			
				<Route
						path="*"
						element={
							<div style={{ padding: "15rem", height: '75vh'  }}>
								<p>There's nothing here!</p>
							</div>
						}
					/>
			</Routes>
			<Outlet />
			<Footer />
		</div>

	);
}

