import React from 'react'

const blood69 = ({ B69data }) => {
    return (
        <>
            {
                B69data.map((curE) => {

                    const { id, source, description } = curE;
                    return (

                        <div className="col p-2" key={id}>
                            <div className="card h-100" data-toggle="lightbox">
                                <img src={source} className="img-fluid" />
                            </div>
                        </div>

                    );


                })
            }
        </>
    )
}

export default blood69