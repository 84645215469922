import React from 'react'

const WhereTable2 = ({ WTD2 }) => {
    console.log(WTD2);
    return (
        <>
            {
                <table className="table table-hover nepali text-start">
                    <thead>
                        <tr>
                            <th scope="col">l;=g+=</th>
                            <th scope="col">ldlt</th>
                            <th scope="col">/Qmbfg ePsf] :yfg</th>
                            <th scope="col">slkmPt</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            WTD2.map((cureE) => {
                                const { id, date, location, remark } = cureE;
                                return (

                                    <tr key={id.toString()}>
                                        <th scope="row">{id}</th>
                                        <td>{date}</td>
                                        <td>{location}</td>
                                        <td>{remark}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            }

        </>
    )
}

export default WhereTable2