import React, { useState } from 'react';
import './tabsToDropdown'
import Home from './home';
import Who from './Who';
import What from './What';
import Where from './Where';
import Knowledge from './Knowledge';
import Gallery from './Gallery';
import GetInvolved from "./GetInvolved";
import Donate from "./Donate";




export default function InnerBody() {

	return (
		<>
			<div className="container py-5">
				<div className=''>
					<div className="d-flex align-items-start">
						<div className="col-2">
							<div className="nav flex-column nav-pills nav-fill me-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
								<button className="btn btn-outline-info active my-1" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><i className="fa-solid fa-door-open"></i> Home</button>
								<button className="btn btn-outline-info my-1" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-who" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i className="fa-solid fa-masks-theater"></i> Who We Are?</button>
								<button className="btn btn-outline-info my-1" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-what" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i className="fa-solid fa-swatchbook"></i> What We Do?</button>
								<button className="btn btn-outline-info my-1" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-where" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i className="fa-solid fa-dharmachakra"></i> Where We Work?</button>
								<button className="btn btn-outline-info my-1" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-knowledge" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i className="fa-regular fa-file-lines"></i> Knowledge Center</button>
								<button className="btn btn-outline-info my-1" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-gallery" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i className="fa-regular fa-images"></i> Gallery</button>
								<button className="btn btn-outline-info my-1" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-getinvolved" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i className="fa-solid fa-handshake"></i> Get Involved</button>
								<button className="btn btn-outline-info my-1" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-donate" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i className="fa-solid fa-dna"></i> Donate</button>
							</div>
						</div>
						<div className="col-10 ">
							<div className="tab-content w-100 ps-4" id="v-pills-tabContent">
								<div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
									<Home />
								</div>
								<div className="tab-pane fade" id="v-pills-who" role="tabpanel" aria-labelledby="v-pills-who-tab">
									<Who />
								</div>
								<div className="tab-pane fade" id="v-pills-what" role="tabpanel" aria-labelledby="v-pills-what-tab">
									<What />
								</div>
								<div className="tab-pane fade" id="v-pills-where" role="tabpanel" aria-labelledby="v-pills-where-tab">
									<Where />
								</div>
								<div className="tab-pane fade" id="v-pills-knowledge" role="tabpanel" aria-labelledby="v-pills-knowledge-tab">
									<Knowledge />
								</div>
								<div className="tab-pane fade" id="v-pills-gallery" role="tabpanel" aria-labelledby="v-pills-gallery-tab">
									<Gallery />
								</div>
								<div className="tab-pane fade" id="v-pills-getinvolved" role="tabpanel" aria-labelledby="v-pills-getinvolved-tab">
									<GetInvolved />
								</div>
								<div className="tab-pane fade" id="v-pills-donate" role="tabpanel" aria-labelledby="v-pills-donate-tab">
									<Donate />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

}