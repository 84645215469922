
const Blood69 =() => [

    {
		id:1,
		source:"images/gallery/blood69/1.JPG",
		description:""
	},
    {
		id:2,
		source:"images/gallery/blood69/2.JPG",
		description:""
	},
	{
		id:3,
		source:"images/gallery/blood69/3.JPG",
		description:""
	},
	{
		id:4,
		source:"images/gallery/blood69/4.JPG",
		description:""
	},
	{
		id:5,
		source:"images/gallery/blood69/5.JPG",
		description:""
	},
	{
		id:6,
		source:"images/gallery/blood69/6.JPG",
		description:""
	},
	{
		id:7,
		source:"images/gallery/blood69/7.JPG",
		description:""
	},
	{
		id:8,
		source:"images/gallery/blood69/8.JPG",
		description:""
	},
	{
		id:9,
		source:"images/gallery/blood69/9.JPG",
		description:""
	},
	{
		id:10,
		source:"images/gallery/blood69/10.JPG",
		description:""
	},
	{
		id:11,
		source:"images/gallery/blood69/11.JPG",
		description:""
	},
	{
		id:12,
		source:"images/gallery/blood69/12.JPG",
		description:""
	},
]
export default Blood69