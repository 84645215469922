const WTdata2 = () => [

    {
		id: 1,
		date: "2067÷08÷10",
		location: ">L ;]tLb]jL pRr dflj ;fn' /fd]%fk",
		remark: "==="
	},
    {
        id: 2,
		date: "2068÷12÷25,26",
		location: ";fn' ('±é]%fk, /fd]%fk",
		remark: "==="
	},
    {
		id: 3,
		date: "2069÷06÷13",
		location: "afnsf]^ art tyf >[)f ;xsf/L ;+:yf ln= afnsf]^ eQmk'/",
		remark: "/fli^o lduf}{nf pkrf/ s]Gb|sf] ;xsfo{df lgz'Ns lduf}{nf k/LIf)f"
	},
    {
		id: 4,
		date: "2069÷07÷24",
		location: ";fn' ('±é]%fk, /fd]%fk",
		remark: "==="
	},
    {
		id: 5,
		date: "2069÷07÷25",
		location: "tfdfsf]zL ;xsf/L c:ktfn, dGynL /fd]%fk",
		remark: "251 hgfsf] lgz'Ns lduf}{nf k/LIf)f, 146 hgfsf] lgz'Ns gfs sfg #f^L k/LIf)f"
	}

]
export default WTdata2;