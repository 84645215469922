import React from 'react';

const Bank = () => {

	return (
		<>
			<div className="py-5">
				<h4>BANK TRANSFER/DEPOSIT</h4>
				<p>Please select mentioned bank account details for deposits/fund transfer.</p>
				<div className="card-text fw-bold">
					Nepal Bank Limited, Dillibazar Branch,  Account Number - 03200100070904000001
				</div>

				<div className="card-text">
					send photo/scan of receipt to <span className='fst-italic text-decoration-underline'>receipt@brasumef.org.np</span>
				</div>



			</div>
		</>
	);

}

export default Bank;