const LifetimeData =() => [

    {
        id: 0,
        img: "images/guardian/netra.jpg",
        name: "Mr Netra Raj Upadhayay Kafle",
        address: ""
    },
    {
        id: 1,
        img: "images/lifetime/1.jpg",
        name: "Mrs Sumitra Kafle",
        address: ""
    },
    
    {
        id: 2,
        img: "images/lifetime/2.jpg",
        name: "Mr Binodbabu Kafle",
        address: ""
    },
    {
        id: 3,
        img: "images/lifetime/3.jpg",
        name: "Mr Upendra Raj Kafle",
        address: ""
    },
    {
        id: 4,
        img: "images/lifetime/4.jpg",
        name: "Mr Suresh Raj Kafle",
        address: ""
    },
    {
        id: 5,
        img: "images/lifetime/5.jpg",
        name: "Mr KoshRaj Kafle",
        address: "Chabahil, Kathmandu"
    },
    {
        id: 6,
        img: "images/lifetime/6.jpg",
        name: "Mrs Bhawani Baral Kafle",
        address: "Chabahil, Kathmandu"
    },
    {
        id: 7,
        img: "images/lifetime/knb.jpg",
        name: "Mr Kedar Nath Bhattarai",
        address: "Gatthaghar, Madhyapur -03"
    },
    {
        id: 8,
        img: "images/lifetime/7.jpg",
        name: "Mr Govind Pd Sharma Kafle",
        address: "Ratopul, Kathmandu"
    },
    {
        id: 9,
        img: "images/lifetime/8.jpg",
        name: "Mrs Bina Pokharel Kafle",
        address: "Ratopul, Kathmandu"
    },
    {
        id: 10,
        img: "images/lifetime/9.jpg",
        name: "Mr Medan Prasad Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 11,
        img: "images/lifetime/10.jpg",
        name: "Mrs Kalpana Ghimire Kafle",
        address: "Balkot, Bhaktapur"
    },
    {
        id: 12,
        img: "images/lifetime/11.jpg",
        name: "Mr Achyut Raj Kafle",
        address: ""
    },
    {
        id: 13,
        img: "images/lifetime/menukaD.jpg",
        name: "Ms Menuka Dhungel",
        address: "Pokhara"
    },
    {
        id: 14,
        img: "images/lifetime/12.jpg",
        name: "Mr Pasupati Kumar Kafle",
        address: ""
    },
    {
        id: 15,
        img: "images/lifetime/13.jpg",
        name: "Mrs Shailaja Sharma Kafle",
        address: ""
    },
    {
        id: 16,
        img: "images/lifetime/14.jpg",
        name: "CA Sujan Kumar Kafle",
        address: ""
    },
    {
        id: 17,
        img: "images/lifetime/15.jpg",
        name: "Mrs Deepa Gyawali Kafle",
        address: ""
    },
    {
        id: 18,
        img: "images/lifetime/16.jpg",
        name: "Eng Sujan Kafle",
        address: ""
    },
    {
        id: 19,
        img: "images/lifetime/17.png",
        name: "Mr Binod Prasad Baral",
        address: ""
    },
    {
        id: 20,
        img: "images/lifetime/18.jpg",
        name: "Mr Prakash Raj Kafle",
        address: ""
    },
    {
        id: 21,
        img: "images/lifetime/19.jpg",
        name: "Dr Kumud Raj Kafle",
        address: ""
    },
    {
        id: 22,
        img: "images/lifetime/20.jpg",
        name: "CA Mammohan Raj Kafle",
        address: ""
    },
    {
        id: 23,
        img: "images/lifetime/21.jpg",
        name: "Mrs Puspana Kafle Parajuli",
        address: ""
    },
    {
        id: 24,
        img: "images/lifetime/22.jpg",
        name: "CA Prabin Kafle",
        address: ""
    },
    {
        id: 25,
        img: "images/lifetime/23.jpg",
        name: "Eng Puskal Kafle",
        address: ""
    },
    {
        id: 26,
        img: "images/lifetime/24.jpg",
        name: "Dr Kaushal Kafle",
        address: ""
    },
    {
        id: 27,
        img: "images/lifetime/25.jpg",
        name: "Dr Rukma Raj Kafle",
        address: ""
    },


]
export default LifetimeData