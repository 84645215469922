import React from 'react';
import '../css/footer.css'

export default function footer() {

	function privacy() {

	}

	return (

		<footer id="footer" className="f-bg pt-5">
			<div className="container">
				<div className='row text-center border-bottom border-2 pb-2'>
					<h4>Call Us</h4>
					<h3>(+977) 9841612703</h3>
				</div>
				<div className="row py-4">
					<div className="col-md-6">

						<h3 className="letter-spacing-1 fw-300">OUR <b>MISSION</b> TO ACHIEVE OUR <b>VISION</b></h3>
						<p className="textJust">
							We, mainly work on several sectors to provide good environment, enriching life, to create better future for underprivileged and helpless people, women, disability person in education, medical aid, safety and clean water supply around Nepal.
						</p>

					</div>
					<div className='col'></div>
					<div className="col-md-5">
						<h3 className="letter-spacing-1">KEEP IN TOUCH</h3>
						<p className='textJust'>Get connected to us through social platform to get Important News & Updates.</p>

						<div className='d-flex py-2'>
							<div className=''>
								<button className='btn btn-outline-warning btn-sm'>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
										<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
									</svg>
								</button>
							</div>
							<div className='px-2'>
								<button className='btn btn-outline-warning btn-sm'>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-messenger" viewBox="0 0 16 16">
										<path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z" />
									</svg>
								</button>
							</div>
							<div className=''>
								<button className='btn btn-outline-warning btn-sm'>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
										<path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="copyright has-social">
				<div className="container text-white p-2">
					<div className='d-flex justify-content-between'>
						<div className="fw-bold py-2">
							&copy; 2079 BRASUMEF  ||<a href='http://www.aen.com.np' target={'_blank'} className="btn btn-sm">♒ </a>
						</div>
						<div className="px-2 py-3">
							<span>EN</span> | <span>NP</span>
						</div>
						<div className="px-2 py-2">
							<button type="button" className='btn btn-outline-dark btn-sm' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
								privacy
							</button>


							<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
								<div className="modal-dialog rounded rounded-4 bg-light text-dark">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="staticBackdropLabel">Privacy</h5>

										</div>
										<div className="modal-body">

											<p class="dropcap textJust">Your privacy is important to us and we have prepared this Privacy Policy to explain to you how we collect, use, and share information we obtain through your use of the Internet sites, applications and services (Services) that are operated by, controlled by or are affiliate with BRASUMEF, including without limitation (collectively, we, us or our). This Privacy Policy does not govern our use of any information you provide to us when you call us, write to us, or communicate with us in any manner other than through the Services. Because the Internet is global, information about you that we collect or that you submit may be transferred to, processed in, and held in countries (including the NEPAL) other than the one in which you reside. By using the Services, you explicitly consent to such use of your information and agree to the terms of this Privacy Policy.
											</p>
											<ul class="list-links">
												<li><a href="#">Information Collection.</a></li>
												<li><a href="#">Information Submission</a></li>
												<li><a href="#">Information Sharing</a></li>
												<li><a href="#">Public Information</a></li>
												<li><a href="#">Cookies</a></li>
												<li><a href="#">Advertising</a></li>
											</ul>

											<blockquote>
												<p>If you have any questions about this Privacy Policy, please feel free to contact us by email at <strong>support@brasumef.org.np</strong></p>

											</blockquote>

										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Understood</button>
										</div>
									</div>
								</div>
							</div>

							<button type="button" className='btn btn-outline-dark btn-sm' data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
								terms
							</button>

							<div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
								<div className="modal-dialog rounded rounded-4 bg-light text-dark">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="staticBackdropLabel">Terms & Condition</h5>
										</div>
										<strong className='text-center'>Welcome to the internet sites, application and services provided by BRASUMEF.
										</strong>
										<div className="modal-body">

											<p class="dropcap textJust py-2">
												BY JOINING OR EACH TIME YOU ACCESS AND USE THE SERVICES, YOU SIGNIFY THAT YOU HAVE READ AND UNDERSTAND, AND AGREE TO BE BOUND BY, THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, YOU ARE NOT PERMITTED TO USE THE SERVICES. These Terms have the same force and effect as an agreement in writing.
											</p>
											<ul class="list-links">
												<li><a href="#">Responsibilities</a></li>
												<li><a href="#">User Submissions</a></li>
												<li><a href="#">Copyright Infringement and Trademark Rights</a></li>
												<li><a href="#">Termination</a></li>
												<li><a href="#">Modifications To Terms & Services</a></li>
											</ul>

											<blockquote>
												<p>If you have any questions about this Terms & Condition, please feel free to contact us by email at <strong>support@brasumef.org.np</strong></p>

											</blockquote>

										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Understood</button>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>

		</footer>

	);

}
