const HonaryData = () => [

    {
        id: 1,
        img: "images/honorary/2.png",
        name: "Divine Mr Kendra Raj Kafle",
        address: ""
    },

    {
        id: 2,
        img: "images/honorary/1.jpg",
        name: "Mr Jeet Bahadur Tamang",
        address: ""
    },


]
export default HonaryData