
const WhatData = () => [

	{
		id:1,
		title:"Education",
		image:"images/what/edu.jpg",
		url:"",
		description:"",
		
	},
	{
		id:2,
		title:"Health",
		image:"images/what/hh.jpg",
		url:"",
		description:"",
		
	},
	{
		id:3,
		title:"Enriching Life",
		image:"images/what/life.jpg",
		url:"",
		description:"",
		
	},
	{
		id:4,
		title:"Infrastructure",
		image:"images/what/temple.jpg",
		url:"/infra",
		description:"",
		
	},
	{
		id:5,
		title:"Donation",
		image:"images/what/donate.jpg",
		url:"",
		description:"",
		
	}
]	


export default WhatData;