import React, { useState } from 'react';
import WhatAPI from './WhatData';
import WhatCard from './WhatCard';

const What = () => {

	const [Whatdata, setWhatdata] = useState(WhatAPI);

	return (
	
			<div className="container">
				<div className="mb-3 w-100 py-4">
					<h5 className="card-title fw-bold fs-4 text-info">PURPOSE</h5>
					<p className="card-text">Our effort for better community</p>
				</div>

				<div className="row row-cols-1 row-cols-md-2 g-4">
					<WhatCard Wdata={Whatdata} />
				</div>
			</div>
	
	);

}

export default What;