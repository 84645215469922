import React from 'react';

const WhereTable1 = ({ WTabledata }) => {
	console.log(WTabledata);
	return (
		<>{
			<table className="table table-hover nepali text-start">
				<thead>
					<tr>
						<th scope="col">l;=g+=</th>
						<th scope="col">ldlt</th>
						<th scope="col">/Qmbfg ePsf] :yfg</th>
						<th scope="col">/Qmbftf ;+Vof</th>
						<th scope="col">;+slnt /ut -lk)^_</th>
					</tr>
				</thead>
				<tbody>
					{
						WTabledata.map((cureE) => {
							const { id, sn, date, location, participants, collection } = cureE;
							return (

								<tr key={id.toString()}>
									<th scope="row">{sn}</th>
									<td>{date}</td>
									<td>{location}</td>
									<td>{participants}</td>
									<td>{collection}</td>
								</tr>


							);
						})
					}
				</tbody>
			</table>
		}
		</>
	);
}

export default WhereTable1;