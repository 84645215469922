import React from 'react';


export default function home() {


	return (
		<>
			<div className="container">
				<div className="mb-3 w-100 border-bottom border-3 py-4">
					<h5 className="card-title fw-bold fs-4 text-info">RECENT UPDATES & AFFAIRS</h5>
					<p className="card-text">Our effort for better community</p>
				</div>
				<span className=''>We will update accordingly.Thank you.</span>
			</div>

		</>

	);

}