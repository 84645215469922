import React from 'react';
import img1 from '../images/slide1.jpg';
import img2 from '../images/slide2.jpg';
import img3 from '../images/slide3.jpg';
import img4 from '../images/slide4.jpg';


export default function carousel() {


	return (

		<div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
			<div className="carousel-indicators">
				<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
				<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
				<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
				<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
			</div>
			<div className="carousel-inner">
				<div className="carousel-item active" data-bs-interval="5000">
					<img src={img1} className="d-block" alt="Family Tree" />
					
				</div>
				<div className="carousel-item" data-bs-interval="5000">
					<img src={img2} className="d-block" alt="HEALTH" />
					
				</div>
				<div className="carousel-item" data-bs-interval="5000">
					<img src={img3} className="d-block" alt="SOCIAL" />
					
				</div>
				<div className="carousel-item" data-bs-interval="5000">
					<img src={img4} className="d-block" alt="SOCIAL" />
					
				</div>
			</div>
			<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
				<span className="carousel-control-prev-icon" aria-hidden="true"></span>
				<span className="visually-hidden">Previous</span>
			</button>
			<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
				<span className="carousel-control-next-icon" aria-hidden="true"></span>
				<span className="visually-hidden">Next</span>
			</button>
		</div>
	);
}


