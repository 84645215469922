import React from 'react'

const guardian = ({ gData }) => {
    return (
        <>
            {
                gData.map((curE) => {

                    const { id, img, name, address } = curE
                    return (

                        <div className="col-md-3 p-2" key={id}>
                            <div className="card border-success border-2 m-2">
                                <div className='row'>
                                    <img src={img} className="card-img-top" alt={name}/>
                                </div>
                                <div className='row'>
                                    <div className="card-body">
                                        <small className="card-title text-dark fs-6">{name}</small>
                                        <p className="card-text">{address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )

                })
            }
        </>
    )
}

export default guardian