import React from 'react'

const honorary = ({ HData }) => {
    return (
        <>{
            HData.map((curE) => {

                const { id, img, name, address } = curE
                return (
                    <div className="col py-2" key={id}>
                        <div className="card bg-info h-100 m-1 p-2">
                            <img src={img} className="card-img-top" alt={name} />
                            <div className="card-body">
                                <h5 className="card-title text-white fs-6">{name}</h5>
                                <p className="card-text">{address}</p>
                            </div>
                        </div>
                    </div>
                )

            })
        }
        </>
    )
}

export default honorary