import React, { useState } from 'react';
import EQData from './data/earthquakeData'
import EQ from './earthQuake'
import B69 from './blood69'
import B69Data from './data/blood69Data'
import Bhojan from './bhojan'
import BSDa from './data/bhojanData'


const Gallery = () => {

	const [EQD, setEQD] = useState(EQData)
	const [B69D, setB69D] = useState(B69Data)
	const [BhojanD, setBhojanD] = useState(BSDa)

	return (
		<>

			<div className="card-body p-2 w-100 mb-4">
				<h5 className="card-title fw-bold fs-4 text-info">OUR STORIES TO TELL</h5>
				<p className="card-text">"Our Gallery"</p>
			</div>
			<div className='mb-4'>
				<div className="text-center py-4">
					<h3 className="text-danger fs-5 fw-bold">2078/2077/2076 FREE FOOD PROGRAMME</h3>
					<span className='text-muted'>ASSISTANCE TO COMMUNITY</span>
				</div>
				<div className="card p-2">
					<div className="row row-cols-1 row-cols-md-3">
						<Bhojan BSData = {BhojanD} />
					</div>
				</div>
			</div>
			<div className='mb-4'>
				<div className="text-center py-4">
					<h3 className="text-danger fs-5 fw-bold">2072 EARTHQUAKE RELIEF PROGRAMME</h3>
					<span className='text-muted'>ASSISTANCE TO COMMUNITY</span>
				</div>
				<div className="card p-2">
					<div className="row row-cols-1 row-cols-md-3">
						<EQ eQuake={EQD} />
					</div>
				</div>
			</div>
			<div className='mb-4'>
				<div className="text-center py-4">
					<h3 className="text-danger fs-5 fw-bold">2069 BLOOD DONATION PROGRAMME</h3>
					<span className='text-muted'>ASSISTANCE TO COMMUNITY</span>
				</div>
				<div className="card p-2">
					<div className="row row-cols-1 row-cols-md-3">
						<B69 B69data={B69D} />
					</div>
				</div>
			</div>
		</>
	);
}

export default Gallery;